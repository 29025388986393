import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { Alert } from '@material-ui/lab'

import {
  surveysFetch,
  surveysStatusFetch,
  surveyCreate,
  getPendingSurveys,
  postPendingSurveys,
  resetPendingSurveys,
} from './actions'
import Surveys from './components/Surveys'
import { unlockUserLockedSurvey } from '../../common/actions'
import addNew from './assets/add-icon.svg'
import useStyles from './styles'
import { SurveyHeader } from '../../components'
import clsx from 'clsx'
import PendingSurveys from './components/Surveys/PendingSurveys'
import { analystFetch } from '../SurveyInfo/actions'

function ManageSurveys({
  surveys,
  surveysFetch,
  statusList,
  surveysStatusFetch,
  history,
  surveycreateres,
  surveyCreate,
  statuscode,
  unlockUserLockedSurvey,
  getPendingSurveys,
  analysts,
  analystFetch,
  postPendingSurveys,
  resetPendingSurveys,
}) {
  document.title = 'OHI Portal - Manage Surveys'
  const classes = useStyles()

  const surveyTypes = ['All Surveys', 'My Surveys']
  const [activeTab, setActiveTab] = useState('My Surveys')
  const [userEmail, setUseremail] = useState('')
  const [isSurveycreating, setIssurveyCreating] = useState(false)
  const [pendingSurveyPopup, setOpenPendingSurveyPopup] = useState(false)
  const [open, setOpen] = useState(false)

  function createNewSurvey() {
    setOpen(false)
    getPendingSurveys()
  }
  let user = JSON.parse(localStorage.getItem('userInfo'))
  let usertype = user?.roleName || ''
  console.log('user', usertype)

  useEffect(() => {
    unlockUserLockedSurvey()
    analystFetch()
    return () => {
      setOpenPendingSurveyPopup(false)
      resetPendingSurveys()
    }
  }, [])

  useEffect(() => {
    if (activeTab === 'My Surveys') {
      setUseremail(user.email)
    } else {
      setUseremail('')
    }
  }, [activeTab])

  useEffect(() => {
    if (surveycreateres.get('isFetched') && isSurveycreating) {
      setIssurveyCreating(false)
      history.push(`/define/client-info/${surveycreateres.get('items')}`)
    }
    if (
      surveycreateres.get('isPendingSurveysDataFetched') &&
      surveycreateres.get('pendingSurveysData').toJS().length
    ) {
      setOpenPendingSurveyPopup(true)
    } else if (
      surveycreateres.get('isPendingSurveysDataFetched') &&
      !surveycreateres.get('pendingSurveysData').toJS().length
    ) {
      if (!isSurveycreating) {
        surveyCreate()
      }
      setIssurveyCreating(true)
    }
  }, [surveycreateres])

  return (
    <Box className={classes.root}>
      <SurveyHeader history={history} statuscode={statuscode} />
      {pendingSurveyPopup && (
        <PendingSurveys
          history={history}
          data={surveycreateres.get('pendingSurveysData').toJS()}
          analysts={analysts}
          postPendingSurveys={postPendingSurveys}
          setOpenPendingSurveyPopup={setOpenPendingSurveyPopup}
        />
      )}
      <Box component="main" className={classes.main}>
        <Box
          display="flex"
          alignItems="center"
          px={4}
          py={2}
          justifyContent="space-between"
          className={classes.headingRow}
        >
          <Box display="flex" className={classes.heading}>
            <Typography
              className={classes.pageHeading}
              component="h1"
              variant="h6"
            >
              Manage Surveys
            </Typography>
          </Box>
          <Box className={classes.surveyActions}>
            <Box
              className={classes.add}
              display="flex"
              onClick={() => setOpen(true)}
              style={{ position: 'relative' }}
            >
              {isSurveycreating && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {usertype == 'Admin' && (
                <>
                  <Box
                    component="img"
                    src={addNew}
                    alt="add new"
                    style={{ width: 19 }}
                  />
                  <Box component="div" p={1} className={classes.newsurveybtn}>
                    Create New Survey
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {usertype !== 'Admin' && (
          <Alert severity="warning">
            <div className={classes.warningctrl}>
              <div className={classes.warningtext}>
                You can no longer create new surveys on the portal, but you can
                still access your existing ones. As per the latest guidelines,
                please use the CES platform for survey creation. Refer to the
                "CES Scope Doc" for details. If you still have any questions,
                contact team at{' '}
                <a href="mailto:OHI-Helpdesk@mckinsey.com">
                  OHI-Helpdesk@mckinsey.com
                </a>
              </div>
              <div className={classes.warningbtn}>
                <Button
                  component="a"
                  target="_blank"
                  className={classes.warniAtag}
                  size="small"
                  href="https://mckinsey.box.com/s/aq22v5q2pi7qx0fvua8bblgdo7zj3d7y"
                  variant="outlined"
                >
                  CES scope Doc
                </Button>
                <Button
                  component="a"
                  target="_blank"
                  href="https://ohisurvey.com/self-serve/"
                  size="small"
                  variant="outlined"
                  className={classes.warniAtag}
                >
                  Go to CES platform
                </Button>
              </div>
            </div>
          </Alert>
        )}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="row"
          className={clsx({ [classes.surveyTabs]: true })}
        >
          {surveyTypes.map((name, index) => (
            <Box
              display="flex"
              key={index}
              py={1}
              px={8}
              className={clsx({
                [classes.typeTab]: true,
                [classes.activeTabclass]: name == activeTab,
              })}
              onClick={() => setActiveTab(name)}
            >
              {name}
            </Box>
          ))}
        </Box>
        {surveys.size ? (
          <Box>
            <Surveys
              surveys={surveys}
              onFetch={surveysFetch}
              statusList={statusList}
              onStatusFetch={surveysStatusFetch}
              history={history}
              onSurveyCreate={surveyCreate}
              surveycreateres={surveycreateres}
              userEmail={userEmail}
              isPostFetched={surveycreateres.get('isPostFetched')}
            />
          </Box>
        ) : null}
      </Box>
      <Dialog open={open}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <p>
                <strong>IMPORTANT</strong>: Please ensure you’ve evaluated
                whether this survey can be setup using the CES platform as per
                latest guidelines.
              </p>
              Please note that you wont't be able to launch this survey, without{' '}
              <b>Legal Checks being confirmed in Salesforce</b>. Take time to
              connect with your SDM to ensure we got legal cleared and marked in
              Salesforce before survey launch. You can use the{' '}
              <b>SYNC button</b> to refresh the connection with Salesforce and
              check whether legal checks has been confirmed.
            </Typography>
            <br />
            <br />
            <Typography align="center">
              Click OK to continue survey set up
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={createNewSurvey}
            className={classes.buttonRound}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

const mapStateToProps = state => ({
  surveys: state.get('surveys'),
  statusList: state.get('surveyStatus'),
  surveycreateres: state.get('surveycreateres'),
  loader: state.get('loader'),
  statuscode: state.get('statuscode'),
  analysts: state.get('analysts'),
})

const mapDispatchToProps = {
  surveysFetch,
  surveysStatusFetch,
  surveyCreate,
  unlockUserLockedSurvey,
  getPendingSurveys,
  analystFetch,
  postPendingSurveys,
  resetPendingSurveys,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveys)
